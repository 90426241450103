import React, { useState, useEffect } from "react";
import { downloadCsv } from "../common/sharedFunctions";
import MaterialTable from "material-table";
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { api } from "common";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { colors } from "../components/Theme/WebTheme";

const Panics = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { completePanic } = api;
  const dispatch = useDispatch();

  const columns = [
    {
      title: "ID",
      field: "id",
      editable: "never",
      headerStyle: { textAlign: "center" },
      cellStyle: { textAlign: "center" },
    },
    {
      title: "ID da Corrida",
      field: "bookingId",
      editable: "never",
      headerStyle: { textAlign: "center" },
      cellStyle: { textAlign: "center" },
    },
    {
      title: "Data de criação",
      field: "date",
      render: (rowData) =>
        rowData.date ? moment(rowData.date).format("lll") : null,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      exportTransformer: (rowData) =>
        new Date(rowData.date).toLocaleDateString() +
        " " +
        new Date(rowData.date).toLocaleTimeString(),
    },
    {
      title: "É o motorista?",
      field: "isDriver",
      editable: "never",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      render: (rowData) => (rowData.isDriver ? "Sim" : "Não"),
    },
    {
      title: "Nome",
      field: "userName",
      editable: "never",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Telefone",
      field: "userPhone",
      editable: "never",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Local",
      field: "address",
      editable: "never",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: "Email",
      field: "userEmail",
      editable: "never",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    // {
    //   title: "Coordenadas",
    //   field: "coordinates",
    //   editable: "never",
    //   cellStyle: { textAlign: "center" },
    //   headerStyle: { textAlign: "center" },
    // },
    {
      title: "Data Processado",
      field: "processDate",
      render: (rowData) =>
        rowData.processDate ? moment(rowData.processDate).format("lll") : null,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      exportTransformer: (rowData) =>
        new Date(rowData.processDate).toLocaleDateString() +
        " " +
        new Date(rowData.processDate).toLocaleTimeString(),
    },
  ];
  
  const [data, setData] = useState([]);
  const panicdata = useSelector((state) => state.panicdata);

  useEffect(() => {
    if (panicdata.panics) {
      setData(panicdata.panics);
      console.log(panicdata.panics)
    } else {
      setData([]);
    }
  }, [panicdata.panics]);

  const [selectedRow, setSelectedRow] = useState(null);

  return panicdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      <style>
        {`
          ::-webkit-scrollbar {
            height: 15px !important;
          }
        `}
      </style>
      <MaterialTable
        title={"Chamadas de emergência"}
        columns={columns}
        style={{
          direction: isRTL === "rtl" ? "rtl" : "ltr",
          borderRadius: "8px",
          boxShadow: "4px 4px 6px #9E9E9E",
        }}
        data={data}
        options={{
          // Diminui tamanho da tabela
          maxBodyHeight: "calc(100vh - 200px)",
          exportCsv: (columns, data) => {
            let hArray = [];
            const headerRow = columns.map((col) => {
              if (typeof col.title === "object") {
                return col.title.props.text;
              }
              hArray.push(col.field);
              return col.title;
            });
            const dataRows = data.map(({ tableData, ...row }) => {
              row.date =
                new Date(row.date).toLocaleDateString() +
                " " +
                new Date(row.date).toLocaleTimeString();
              row.processDate = row.processDate
                ? new Date(row.processDate).toLocaleDateString() +
                  " " +
                  new Date(row.processDate).toLocaleTimeString()
                : "";
              let dArr = [];
              for (let i = 0; i < hArray.length; i++) {
                dArr.push(row[hArray[i]]);
              }
              return Object.values(dArr);
            });
            const { exportDelimiter } = ",";
            const delimiter = exportDelimiter ? exportDelimiter : ",";
            const csvContent = [headerRow, ...dataRows]
              .map((e) => e.join(delimiter))
              .join("\n");
            const csvFileName = "download.csv";
            downloadCsv(csvContent, csvFileName);
          },
          exportButton: {
            csv: settings.AllowCriticalEditsAdmin,
            pdf: false,
          },
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
          }),
          editable: {
            backgroundColor: colors.Header_Text,
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: 'Lucida Console", "Courier New", monospace',
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: colors.Header_Text_back,
            color: "#fff",
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: 'Lucida Console", "Courier New", monospace',
          },
        }}
        localization={{
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}
        actions={[
          (rowData) => ({
            icon: "check",
            tooltip: "Completar Chamado",
            disabled: rowData.processed,
            onClick: (event, rowData) => {
              dispatch(completePanic(rowData));
            },
          }),
        ]}
      />
    </div>
  );
};

export default Panics;
