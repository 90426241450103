import {
  FETCH_ALL_PANICS,
  FETCH_ALL_PANICS_SUCCESS,
  FETCH_ALL_PANICS_FAILED,
  EDIT_PANICS_SUCCESS,
  EDIT_PANICS_FAILED,
  DELETE_PANICS,
  DELETE_PANICS_SUCCESS,
  DELETE_PANICS_FAILED,
  EDIT_PANICS,
} from "../store/types";

import { firebase } from "../config/configureFirebase";
import {
  onValue,
  set,
  push,
  update,
  remove,
  child,
} from "firebase/database";


export const fetchPanic = () => (dispatch) => {
  const { panicAddRef } = firebase;

  dispatch({
    type: FETCH_ALL_PANICS,
    payload: null,
  });

  onValue(panicAddRef, (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        return data[i];
      });
      dispatch({
        type: FETCH_ALL_PANICS_SUCCESS,
        payload: arr.reverse(),
      });
    } else {
      dispatch({
        type: FETCH_ALL_PANICS_FAILED,
        payload: "No Panic available.",
      });
    }
  });
};

export const completePanic = (entry) => (dispatch) => {
  const { panicAddRef } = firebase;

  update(child(panicAddRef, entry.id), {
    ...entry,
    processed: true,
    processDate: new Date().getTime(),
  });

  dispatch({
    type: FETCH_ALL_PANICS,
    payload: null,
  });
};

export const editPanic = (panic, method) => async (dispatch) => {
  const { panicAddRef, panicEditRef } = firebase;

  dispatch({
    type: EDIT_PANICS,
    payload: { method, panic },
  });

  if (method === "Add") {
    push(panicAddRef, panic);
  } else if (method === "Delete") {
    remove(panicEditRef(panic.id));
  } else {
    set(panicEditRef(panic.id), panic);
  }
};
