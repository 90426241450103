export const FirebaseConfig = {
	"projectId": "upps-acessoweb",
	"appId": "1:171829750312:web:6d52c7eca28839f43f231d",
	"databaseURL": "https://upps-acessoweb-default-rtdb.firebaseio.com",
	"storageBucket": "upps-acessoweb.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyD_erlk-XR2UrrlvErkKSAaiL7JhQzVrWk",
	"authDomain": "upps-acessoweb.firebaseapp.com",
	"messagingSenderId": "171829750312",
	"measurementId": "G-ZFBYKS5G2D"
};