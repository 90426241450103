import {
  FETCH_ALL_PANICS,
  FETCH_ALL_PANICS_SUCCESS,
  FETCH_ALL_PANICS_FAILED,
  EDIT_PANICS_SUCCESS,
  EDIT_PANICS_FAILED,
  DELETE_PANICS,
  DELETE_PANICS_SUCCESS,
  DELETE_PANICS_FAILED,
  EDIT_PANICS,
} from "../store/types";

export const INITIAL_STATE = {
  panics: null,
  loading: false,
};

export const panicreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_PANICS:
      return {
        ...state,
      };
    case FETCH_ALL_PANICS_SUCCESS:
      return {
        ...state,
        panics: action.payload,
      };
    case FETCH_ALL_PANICS_FAILED:
      return {
        ...state,
        panics: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case EDIT_PANICS:
      return state;
    default:
      return state;
  }
};
