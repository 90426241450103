import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import CarsList from './CarsList'
import ApprovedCars from './ApprovedCars';
import PendingCars from './PendingCars';

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Users() {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  let query = useQuery();

  const auth = useSelector(state => state.auth);
  const [role, setRole] = React.useState(null);

  React.useEffect(() => {
    if(auth.profile && auth.profile.usertype){
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  React.useEffect(() => {
    let driver = query.get("driver")
    if(driver) {
      setValue(1)
    }
  }, [query]);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab style={{marginRight:"20px"}} label={t('approved_cars')} {...a11yProps(0)} />
          <Tab style={{marginRight:"20px"}} label={t('pending_cars')} {...a11yProps(1)} />
          {/* Todos os carros */}
          {/* <Tab style={{marginRight:"20px"}} label={t('Carros')} {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ApprovedCars/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PendingCars/>
      </TabPanel>
      {/* Todos os carros */}
      {/* <TabPanel value={value} index={2}>
        <CarsList/>
      </TabPanel> */}
    </Box>
  );
}