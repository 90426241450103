import {
  FETCH_CARS,
  FETCH_CARS_SUCCESS,
  FETCH_CARS_FAILED,
  EDIT_CAR,
} from "../store/types";
import store from "../store/store";
import { firebase } from "../config/configureFirebase";
import { onValue, update, set, child, remove, push } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const fetchCars = () => (dispatch) => {
  const { carsRef } = firebase;

  dispatch({
    type: FETCH_CARS,
    payload: null,
  });

  const userInfo = store.getState().auth.profile;

  onValue(carsRef(userInfo.uid, userInfo.usertype), (snapshot) => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        return data[i];
      });
      dispatch({
        type: FETCH_CARS_SUCCESS,
        payload: arr,
      });
    } else {
      dispatch({
        type: FETCH_CARS_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_cars,
      });
    }
  });
};

export const editCar = (car, method) => async (dispatch) => {
  const { singleUserRef, carAddRef, carEditRef, carImage } = firebase;
  dispatch({
    type: EDIT_CAR,
    payload: { method, car },
  });
  if (method === "Add") {
    push(carAddRef, car);
  } else if (method === "Delete") {
    remove(carEditRef(car.id));
  } else if (method === "UpdateImage") {
    let data = car;

    if (typeof car.car_image_frente !== "string") {
      await uploadBytesResumable(carImage(car.id), car.car_image_frente);
      let image = await getDownloadURL(carImage(car.id));

      data.car_image_frente = image;
    }

    if (typeof car.car_image_lateraldireita !== "string") {
      await uploadBytesResumable(
        carImage(car.id),
        car.car_image_lateraldireita
      );
      let image = await getDownloadURL(carImage(car.id));

      data.car_image_lateraldireita = image;
    }

    if (typeof car.car_image_lateralesquerda !== "string") {
      await uploadBytesResumable(
        carImage(car.id),
        car.car_image_lateralesquerda
      );
      let image = await getDownloadURL(carImage(car.id));

      data.car_image_lateralesquerda = image;
    }

    if (typeof car.car_image_traseira !== "string") {
      await uploadBytesResumable(carImage(car.id), car.car_image_traseira);
      let image = await getDownloadURL(carImage(car.id));

      data.car_image_traseira = image;
    }

    if (typeof car.car_image_documento !== "string") {
      await uploadBytesResumable(carImage(car.id), car.car_image_documento);
      let image = await getDownloadURL(carImage(car.id));

      data.car_image_documento = image;
    }

    set(carEditRef(car.id), data);
    if (car.active && car.driver) {
      update(singleUserRef(car.driver), {
        updateAt: new Date().getTime(),
        car_image: data.car_image_frente,
      });
    }
  } else {
    set(carEditRef(car.id), car);
  }
};

export const createUserCar = (newData) => (dispatch) => {
  const { auth, carAddRef, singleUserRef, carImage } = firebase;

  var carId = push(carAddRef).key;

  set(child(carAddRef, carId), newData);

  if (newData.active) {
    let updateDataNew = {
      carType: newData.carType,
      vehicleNumber: newData.vehicleNumber,
      vehicleMake: newData.vehicleMake,
      vehicleModel: newData.vehicleModel,
      other_info: newData.other_info,
      carApproved: newData.approved,
      updateAt: new Date().getTime(),
    };
    update(singleUserRef(auth.currentUser.uid), updateDataNew);
  }

  return carId;
};

export const updateUserCarImage = (carId, blob, active) => async (dispatch) => {
  const { auth, carEditRef, singleUserRef, carImage } = firebase;

  let updateData = {
    updateAt: new Date().getTime(),
  };

  await uploadBytesResumable(carImage(carId, 0), blob[0]);
  let image1 = await getDownloadURL(carImage(carId, 0));

  updateData.car_image_frente = image1;

  await uploadBytesResumable(carImage(carId, 1), blob[1]);
  let image2 = await getDownloadURL(carImage(carId, 1));

  updateData.car_image_lateraldireita = image2;

  await uploadBytesResumable(carImage(carId, 2), blob[2]);
  let image3 = await getDownloadURL(carImage(carId, 2));

  updateData.car_image_lateralesquerda = image3;

  await uploadBytesResumable(carImage(carId, 3), blob[3]);
  let image4 = await getDownloadURL(carImage(carId, 3));
  updateData.car_image_traseira = image4;

  await uploadBytesResumable(carImage(carId, 4), blob[4]);
  let image5 = await getDownloadURL(carImage(carId, 4));
  updateData.car_image_documento = image5;

  update(carEditRef(carId), updateData);

  if (active) {
    let updateDataNew = {
      car_image: updateData.car_image_frente,
      updateAt: new Date().getTime(),
    };
    update(singleUserRef(auth.currentUser.uid), updateDataNew);
  }
};

export const updateUserCarWithImage = (newData, blob, type) => (dispatch) => {
  const { auth, carAddRef, singleUserRef, carImage } = firebase;

  var carId = push(carAddRef).key;

  uploadBytesResumable(carImage(carId, type), blob)
    .then(() => {
      blob.close();
      return getDownloadURL(carImage(carId, type));
    })
    .then((url) => {
      newData.car_image = url;
      set(child(carAddRef, carId), newData);
      if (newData.active) {
        let updateData = {
          carType: newData.carType,
          vehicleNumber: newData.vehicleNumber,
          vehicleMake: newData.vehicleMake,
          vehicleModel: newData.vehicleModel,
          vehicleYear: newData.vehicleYear,
          other_info: newData.other_info,
          carApproved: newData.approved,
          updateAt: new Date().getTime(),
        };

        if (type === 1) {
          updateData.car_image_frente = url;
        }
        if (type === 2) {
          updateData.car_image_lateraldireita = url;
        }
        if (type === 3) {
          updateData.car_image_lateralesquerda = url;
        }
        if (type === 4) {
          updateData.car_image_traseira = url;
        }

        update(singleUserRef(auth.currentUser.uid), updateData);
      }
    });
};
